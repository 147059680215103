<template>
    <div class="video-overlay" v-on:click="$refs.player.play()">
        <video
            controls
            width="100%"
            v-on:play="onPlay"
            v-on:ended="hasBeenPlayed = false"
            v-on:pause="onPause"
            ref="player"
        >
            <source :src="video.file_url" type="video/mp4"/>
        </video>
    </div>
</template>

<script>
import ArtistVideoPlay from "@/models/ArtistVideoPlay";

export default {
    name: "ArtistVideo",
    props: ['video', 'artist'],
    data: function () {
        return {
            hasBeenPlayed: false,
        }
    },
    methods: {
        saveVidePlay() {
            const artistVideoPlay = new ArtistVideoPlay()
            artistVideoPlay.video_id = this.video.id;
            artistVideoPlay.save()
        },
        onPlay() {
            this.hasBeenPlayed = true
            this.hideCover()
            this.$emit('play-pause', true)
        },
        onPause() {
            this.showCover()
            this.$emit('play-pause', false)
        },
        showCover() {
            this.$el.classList.remove('playing');
        },
        hideCover() {
            this.$el.classList.add('playing')
        }
    },
    watch: {
        hasBeenPlayed(val) {
            if (!val) {
                return;
            }

            const user = this.$auth.user()

            if (user && user.id === this.artist.user_id) {
                return
            }

            this.saveVidePlay()
        }
    }
}
</script>

<style scoped>
.video-overlay {
    position: relative;
    height: 100%;
}
.video-overlay  video {
    height: 100%;
    object-fit: cover;
}

.video-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 255, 0.342);
    background-image: url("../assets/img/play-video.svg");
    background-size: 95px;
    background-position: center;
    cursor: pointer;
}

.playing.video-overlay::after {
    content: none;
}
</style>
