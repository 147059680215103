export default async function (url, days = 10, format = 'M j') {
    const res = await window.axios.get(url, {
        params: {
            days,
            format
        }
    });

    return res.data.data
}
