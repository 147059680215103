import Model from './Model'
import newCounts from "@/models/helpers/new-counts";

export default class Venue extends Model {
    // Set the resource route of the model
    resource() {
        return 'venues'
    }

    static getNewCounts = async function (days = 10, format = 'Y-m-d') {
        return newCounts(`/api/venues/new-counts`, days, format)
    }
}
