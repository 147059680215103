<template>
    <v-card class="py-2 px-4 mx-auto" rounded="0" v-bind="$props">
        <v-list>
            <v-list-item>
                <div class="d-flex" style="width: 100%">
                    <v-rating
                        class="justify-content-start"
                        :value="parseFloat(review.score)"
                        color="yellow accent-4"
                        dense
                        readonly
                        empty-icon="mdi-star"
                        background-color="#9a9a9a"
                        half-icon="mdi-star-half-full"
                        half-increments
                        size="18"
                    ></v-rating>
                    <!--<div class="ml-auto">
                        <v-btn>
                            <v-icon>
                                mdi-reply
                            </v-icon>
                            Reply
                        </v-btn>
                        <v-btn>
                            <v-icon>
                                mdi-flag
                            </v-icon>
                            Flag
                        </v-btn>
                    </div>-->
                </div>
            </v-list-item>
            <v-list-item class="my-2">
                <div class="d-flex">
                    <p class="text--primary subtitle-1 font-weight-light">
                        {{ review.text }}
                    </p>
                </div>
            </v-list-item>
            <v-list-item>
                <div class="d-flex" style="width: 100%">
                    <!-- <div class="d-flex align-self-start">
                        <p class="text--secondary subtitle-1 font-weight-thin">
                            Was is helpful?
                        </p>
                       <div class="ml-2 mt-n2">
                            <v-btn icon >
                                <v-icon :color="true ? 'success' : ''">
                                    mdi-thumb-up-outline
                                </v-icon>
                            </v-btn>
                            <v-badge
                                color="grey"
                                :content="review.up_votes_count"
                                :value="review.up_votes_count"
                                inline
                            >
                            </v-badge>
                            <v-btn icon>
                                <v-icon :color="'error'">
                                    mdi-thumb-down-outline
                                </v-icon>
                            </v-btn>
                            <v-badge
                                color="grey"
                                :content="review.down_votes_count"
                                :value="review.down_votes_count"
                                inline
                            >
                            </v-badge>
                        </div>
                    </div> -->
                    <div class="ml-auto">
                        <p class="text--secondary subtitle-1 font-weight-thin">
                            {{ formattedContact }}
                        </p>
                    </div>
                </div>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: "ArtistReviewCard",
    props: ['review'],
    computed: {
        formattedContact() {
            return this.$dayjs(this.review.created_at).format('MMM DD, YYYY');
        },
    },
}
</script>

<style scoped>

</style>
