import Model from './Model'

export default class Gig extends Model {
    // Set the resource route of the model
    resource() {
        return 'gigs'
    }

    confirm() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/gigs/${this.id}/confirm`,
                data: this
            }, { forceMethod: true })
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }

    reject() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/gigs/${this.id}/reject`,
                data: this
            }, { forceMethod: true })
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }
}
