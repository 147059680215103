<template>
    <v-footer class="footer">
        <v-container>
            <v-row class="align-center">
                <v-col cols="12" md="4" class="d-flex flex-wrap justify-center pt-6">
                    <router-link
                        :to="{name: 'home'}"
                        class="footer__logo"
                        >
                        <svg class="logo" width="320" height="91"><use xlink:href="#gt-logo" /></svg>
                    </router-link>
                    <a
                        class="footer__link"
                        :href="'mailto:' + email"
                    >
                        {{ email }}
                    </a>
                </v-col>
                <v-col cols="12" md="4" class="d-flex flex-column">
                    <router-link
                        v-for="link in links"
                        :key="link.name"
                        :to="{name: link.name}"
                        class="footer-menu__link text-center text-md-left"
                    >
                    {{ link.title }}
                    </router-link>
                    <router-link
                        v-if="!$auth.user()"
                        :to="{name: 'login'}"
                        class="footer-menu__link text-center text-md-left"
                    >
                        Sign Up
                    </router-link>
                </v-col>
                <v-col cols="12" md="4">
                    <div v-for="(artist,i) in featuredArtists"
                        :key="`footer-artist-${i}`" >
                        <router-link :to="{name: 'artist.public', params: {slug: artist.slug}}">
                            <v-row align="center">
                                <v-col cols="3">
                                    <v-img
                                        :src="artist.imgUrl"
                                        aspect-ratio="1"
                                        contain
                                        class="white"
                                    ></v-img>
                                </v-col>
                                <v-col cols="9">
                                    <h4 class="mb-2">{{ artist.name }}</h4>
                                    <p class="mb-0">{{ artist.address }}</p>
                                </v-col>
                            </v-row>
                        </router-link>
                    </div>
                    <div v-for="(venue,i) in featuredVenues"
                        :key="`footer-venue-${i}`" >
                        <router-link :to="{name: 'venue.public', params: {slug: venue.slug}}">
                            <v-row align="center">
                                <v-col cols="3">
                                    <v-img
                                        :src="venue.imgUrl"
                                        aspect-ratio="1"
                                    ></v-img>
                                </v-col>
                                <v-col cols="9">
                                    <h4 class="mb-2">{{ venue.name }}</h4>
                                    <p class="mb-0">{{ venue.address }}</p>
                                </v-col>
                            </v-row>
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
import Artist from "@/models/Artist";
import Venue from "@/models/Venue";

export default {
    name: "app-footer",
    data: () => ({
        featuredArtists: [],
        featuredVenues: [],
        links: [
            {name: 'about', title: 'About'},
            {name: 'how-it-works', title: 'How it works'},
            {name: 'privacy-policy', title: 'Privacy policy'},
            {name: 'terms-of-service', title: 'Terms of service'},
        ],
        email: 'Info@GrubTunes.com',
    }),
    methods: {
        async fetchArtists() {
            this.artists = await Artist.custom('artists/public').where('max_plan', 1).orderBy('random').limit(1).get();
            this.getFeaturedArtists(this.artists);
        },
        getFeaturedArtists(arr) {
            arr.forEach((item) => {
                this.featuredArtists.push({
                    name: item.name,
                    slug: item.slug,
                    address: item.city.display_name,
                    imgUrl: item.avatar_url,
                })
            })
        },
        async fetchVenues() {
            const venues = await Venue.custom('venues/public').where('max_plan', 1).orderBy('random').limit(1).get();
            this.venues = venues.data
            this.getFeaturedVenues(this.venues);
        },
        getFeaturedVenues(arr) {
            arr.forEach((item) => {
                this.featuredVenues.push({
                    name: item.name,
                    slug: item.slug,
                    address: item.address,
                    imgUrl: item.featured_image,
                })
            })
        }
    },
    mounted() {
        this.fetchArtists()
        this.fetchVenues()
    },
}
</script>

<style scoped>

</style>
