<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="profile__link-btn">
                <a
                    v-bind="attrs"
                    v-on="on"
                >Book Now</a>
            </div>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Book an Event
            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title" v-model="gig.title"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Artist" disabled v-model="artist.name"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-datetime-picker label="Starts At" v-model="gig.starts_at"></v-datetime-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-datetime-picker label="Ends At" v-model="gig.ends_at">
                                <template slot="timeIcon">
                                    TIME
                                </template>
                                <template slot="dateIcon">
                                    Date
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="submitRequest">Submit a request</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <!--            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                            >
                                I accept
                            </v-btn>
                        </v-card-actions>-->
        </v-card>
    </v-dialog>
</template>

<script>
import Gig from "@/models/Gig";
import {mapMutations} from "vuex";
import Venue from "@/models/Venue";

export default {
    name: "BookArtistByVenueModal",
    props: ['artist'],
    data: function () {
        return {
            dialog: false,
            gig: {
                artist_id: this.artist.id,
                title: '',
                starts_at: null,
                ends_at: null,
            }
        }
    },
    methods: {
        async submitRequest() {
            const gig = new Gig(this.gig);
            gig.is_confirmed_by_venue = true

            this.gig = await gig.save()
            if (gig.id) {
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Request sent'})
                this.dialog = false
            }
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        let venue = await Venue.where('user_id', this.$auth.user().id).first();
        this.gig.venue_id = venue.id
    }
}
</script>

<style scoped>

</style>
