<template>
    <v-row no-gutters align="center" class="audio-player">
        <v-col class="audio-player__title">
            <span class="audio-player__counter">{{ counter }}.</span>
            <span>{{ track.title }}</span>
        </v-col>
        <v-col class="audio-player__timeline">
            <!--    <v-icon v-on:click.prevent="stop" x-large color="black">mdi-stop</v-icon> -->
            <v-icon v-on:click.prevent="play" v-show="!isPlaying" x-large color="black">mdi-play</v-icon>
            <v-icon v-on:click.prevent="pause" v-show="isPlaying" x-large color="black">mdi-pause</v-icon>
            <v-slider
                v-model="position"
                v-on:change="changePosition"
                v-on:mousedown="playerSliderActive = true"
                v-on:mouseup="playerSliderActive = false"
                dense
                hide-details
                track-color="black"
            />
            <div class="audio-player__timer">
                {{ currentTime }}/{{ durationTime }}
            </div>
        </v-col>
        <v-col class="audio-player__volume">
            <v-icon v-on:click.prevent="mute" v-show="muted" x-large color="black">mdi-volume-off</v-icon>
            <v-icon v-on:click.prevent="mute" v-show="!muted" x-large color="black">mdi-volume-high</v-icon>
            <v-slider
                v-model.lazy.number="volume" min="0" max="100"
                dense
                hide-details
                track-color="black"
            />
        </v-col>

        <v-col>
            <audio
                v-on:loadeddata="load"
                v-on:stop="stop"
                v-on:timeupdate="update"
                v-on:ended="hasBeenPlayed = false"
                ref="player"
            >
                <source :src="track.file_url"/>
            </audio>
        </v-col>
    </v-row>
</template>

<script>
import ArtistTrackPlay from "@/models/ArtistTrackPlay";

const convertTimeHHMMSS = (val) => {
    let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
    return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
};

export default {
    name: "TrackView",
    props: ['track', 'artist', 'counter', 'isPlaying'],
    data: function () {
        return {
            hasBeenPlayed: false,

            position: 0,
            audio: undefined,
            currentSeconds: 0,
            durationSeconds: 0,
            loaded: false,
            previousVolume: 35,
            volume: 75,
            playerSliderActive: false,
        }
    },
    methods: {
        saveTrackPlay() {
            const artistTrackPlay = new ArtistTrackPlay()
            artistTrackPlay.track_id = this.track.id;
            artistTrackPlay.save()
        },
        play() {
            this.hasBeenPlayed = true
            this.$emit('play-button-click', this.counter)
        },
        pause() {
            this.$emit('play-button-click', null)
        },
        changePosition(position) {
            this.audio.currentTime = position / 100 * this.audio.duration;
        },
        load() {
            if (this.audio.readyState < 2) {
                throw new Error('Failed to load sound file.');
            }

            this.loaded = true;
            this.durationSeconds = parseInt(this.audio.duration);
        },
        mute() {
            if (this.muted) {
                return this.volume = this.previousVolume;
            }
            this.previousVolume = this.volume;
            this.volume = 0;
        },
        stop() {
            this.audio.currentTime = 0;
        },
        update() {
            if (this.playerSliderActive) {
                return
            }

            this.currentSeconds = parseInt(this.audio.currentTime);
            this.position = this.percentComplete;
        }
    },
    mounted() {
        this.audio = this.$refs.player;
    },
    watch: {
        hasBeenPlayed(val) {
            if (!val) {
                return;
            }

            const user = this.$auth.user()

            if (user && user.id === this.artist.user_id) {
                return
            }

            this.saveTrackPlay()
        },
        isPlaying(value) {
            if (value) {
                return this.audio.play();
            }
            this.audio.pause();
        },
        volume() {
            this.audio.volume = this.volume / 100;
        },
        position(val) {
            if (!this.playerSliderActive) {
                return
            }

            this.currentSeconds = parseInt((val * this.durationSeconds) / 100);
        }
    },
    computed: {
        currentTime() {
            return convertTimeHHMMSS(this.currentSeconds);
        },
        durationTime() {
            return convertTimeHHMMSS(this.durationSeconds);
        },
        percentComplete() {
            return parseInt(this.currentSeconds / this.durationSeconds * 100);
        },
        muted() {
            return this.volume / 100 === 0;
        },
    },
}
</script>

<style scoped>

</style>
