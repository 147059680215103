<template>
    <front-layout class="artist">
        <div class="profile__uptitle page__top-line">
            <v-container py-4>
                Artist Profile
            </v-container>
        </div>
        <v-container fluid px-0>
            <v-row no-gutters>
                <v-col md="5" lg="3" class="black d-none d-md-flex justify-center">
                    <v-img
                        :src="artist.avatar_url"
                        width="100%"
                    />
                </v-col>
                <v-col>
                    <div class="artist__header fill-height d-flex d-sm-block flex-column">
                        <div class="artist__title-wrap">
                            <h1 class="artist__title">{{ artist.name }}</h1>
                            <p v-if="artist.subtitle" class="artist__subtitle">{{ artist.subtitle }}</p>
                            <div
                                v-if="artist.genres"
                                >
                                <v-chip v-for="genre in artist.genres"
                                    :key="`genre-${genre.name}`"
                                    class="mr-4"
                                    >
                                    {{ genre.name }}
                                </v-chip>
                            </div>
                        </div>
                        <div class="profile__info flex-sm-row justify-sm-space-around">
                            <div class="d-md-none pa-0 mb-10 mb-sm-0 col-sm-6 black">
                                <v-img
                                    :src="artist.avatar_url"
                                    contain
                                />
                            </div>
                            <div>
                                <div>
                                    <p class="mb-7">{{ artist.city.display_name }}</p>
                                    <p class="profile__link-tel" v-if="artist.phone_number">
                                        <a :href="'tel:' + artist.phone_number">
                                            {{ artist.phone_number }}
                                        </a>
                                    </p>
                                </div>
                                <div>
                                    <div class="profile__link-btn" v-if="artist.website_url">
                                        <a v-bind:href="artist.website_url" target="_blank">
                                            Website
                                        </a>
                                    </div>
                                    <div class="profile__link-btn" v-if="artist.merch_url">
                                        <a v-bind:href="artist.merch_url" target="_blank">
                                            Merchandise
                                        </a>
                                    </div>
                                    <book-artist-by-venue-modal
                                        :artist="artist"
                                        v-if="$auth.check('venue') && artist.id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="artist__upcomings">
                            <div class="container">
                                <h4 class="text-h4 white--text text-center mb-7">
                                    Upcoming Gigs
                                </h4>
                                <v-simple-table class="profile__gigs-table">
                                    <tbody
                                        v-bind:key="`gig-${gig.id}`"
                                        v-for="gig in artist.upcoming_gigs.slice(0, gigPage * 3)"
                                    >
                                    <tr>
                                        <td colspan="4" class="font-weight-medium">
                                            {{ gig.title }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ localeDate(gig.starts_at) }}
                                        </td>
                                        <td class="text-no-wrap">
                                            {{ localeTime(gig.starts_at) }}
                                        </td>
                                        <td>
                                            {{ gig.city['display_name'] }}
                                        </td>
                                        <td class="text-right">
                                            <v-btn
                                                icon
                                                color="black"
                                                :href="gig.livestream_url">
                                                <svg width="36" height="36" viewBox="0 0 24 24">
                                                    <path fill="currentColor"
                                                          d="M12,2C15.31,2 18,4.66 18,7.95C18,12.41 12,19 12,19C12,19 6,12.41 6,7.95C6,4.66 8.69,2 12,2M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M20,19C20,21.21 16.42,23 12,23C7.58,23 4,21.21 4,19C4,17.71 5.22,16.56 7.11,15.83L7.75,16.74C6.67,17.19 6,17.81 6,18.5C6,19.88 8.69,21 12,21C15.31,21 18,19.88 18,18.5C18,17.81 17.33,17.19 16.25,16.74L16.89,15.83C18.78,16.56 20,17.71 20,19Z"></path>
                                                </svg>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                                <div v-if="displayLoadMoreGigs" class="profile__more-btn">
                                    <v-btn @click="loadMoreGigs">Load More</v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="white primary--text">
            <v-container>
                <v-row class="pt-3">
                    <v-col cols="12" md="4">
                        <h4 class="text-h4 font-weight-medium mb-6">Bio</h4>
                        {{ artist.bio }}
                    </v-col>
                    <v-col>
                        <h4 class="text-h4 font-weight-medium mb-6">Tracks</h4>
                        <track-view
                            v-for="(track, counter) in artist.tracks"
                            :track="track"
                            :artist="artist"
                            :key="`track-`+track.id"
                            :counter="counter + 1"
                            :isPlaying="activePlayerId === counter + 1"
                            v-on:play-button-click="onPlayButtonClick"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div v-if="artistVideos">
            <v-slide-group
                :show-arrows="showArrows">
                <v-slide-item
                    v-for="video in artistVideos"
                    :key="`video-${video.id}`"
                >
                    <v-sheet
                        class="v-slide-group__slide"
                        :width="slideWidth"
                        :height="slideWidth/16*9"
                        tile
                        outlined
                    >
                        <artist-video
                            :video="video"
                            :artist="artist"
                            @play-pause="toggleArrows"
                        />
                    </v-sheet>
                </v-slide-item>
            </v-slide-group>
        </div>
        <div class="artistPrimary">
            <v-container>
                <v-row class="justify-center">
                    <v-col lg="8">
                        <div v-for="review in artist.reviews" :key="`review-`+review.id">
                            <artist-review-card :review="review"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="justify-center" v-if="showReviewForm">
                    <v-col lg="8">
                        <artist-review-form :artist="artist" @review-created="init"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";
import Artist from "@/models/Artist";
import TrackView from "@/components/TrackView";
import ArtistReviewForm from "@/components/cards/ArtistReviewForm";
import ArtistReviewCard from "@/components/cards/ArtistReviewCard";
import BookArtistByVenueModal from "@/components/modal/BookArtistByVenueModal";
import ArtistProfileView from "@/models/ArtistProfileView";
import ArtistVideo from "@/components/ArtistVideo";

export default {
    name: "Artist",
    components: {ArtistVideo, BookArtistByVenueModal, ArtistReviewForm, ArtistReviewCard, TrackView, FrontLayout},
    computed: {
        artistVideos() {
            return this.artist.videos ? this.artist.videos.slice(0, 3) : [];
        },
        displayLoadMoreGigs() {
            return this.artist.upcoming_gigs.length > this.gigPage * 3
        },
        showReviewForm() {
            if (!this.artist.id) {
                return false;
            }

            const user = this.$auth.user();

            if (!user) {
                return false;
            }

            return !user["roles"].includes('admin')
        },
    },
    data: function () {
        return {
            artist: {
                user: {},
                tracks: [],
                city: {},
                upcoming_gigs: [],
                genres: []
            },
            gigPage: 1,
            activePlayerId: null,
            showArrows: true,
            slideWidth: 0,
        }
    },
    methods: {
        async init() {
            let artistSlug = this.$route.params.slug

            if (!artistSlug) {
                return;
            }

            this.artist = await Artist.custom('artists/slug/' + artistSlug).first()
            const user = this.$auth.user();

            if (!user || user.id !== this.artist.user_id) {
                let profileView = new ArtistProfileView({artist_id: this.artist.id})
                await profileView.save()
            }
        },
        loadMoreGigs() {
            this.gigPage++;
        },
        formatDate(date) {
            return this.$date(date).format('dddd, MMM DD, YYYY HH:mm')
        },
        localeDate(date) {
            return (new Date(date)).toLocaleDateString(
                'en-US',
                {weekday: 'long', month: 'short', day: 'numeric', year: 'numeric'}
            )
        },
        localeTime(date) {
            return (new Date(date)).toLocaleTimeString(
                'en-US',
                {hour: '2-digit', minute: '2-digit'}
            )
        },
        onPlayButtonClick(playerId) {
            this.activePlayerId = playerId;
        },
        toggleArrows() {
            this.showArrows = !this.showArrows
        },
        getSlideWidth() {
            const width = document.documentElement.clientWidth;
            return (width > 960) ? (width + 8) / 3 : (width > 600) ? (width + 8) / 2 : width + 8
        },
        onResize() {
            this.slideWidth = this.getSlideWidth()
        },
    },
    mounted() {
        this.init()
        this.onResize()

        window.addEventListener('resize', this.onResize, {passive: true})
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, {passive: true})
    }
}
</script>

<style scoped>

</style>
