<template>
    <div class="d-flex flex-column flex-grow-1">
        <svg-sprite />
        <top-menu :is-homepage="isHomepage" />
        <v-main class="page__main main">
            <slot> <!--CONTENT--> </slot>
        </v-main>
        <app-footer app/>
    </div>
</template>

<script>
    import TopMenu from "../components/TopMenu";
    import AppFooter from "../components/AppFooter";
    import SvgSprite from "../components/SvgSprite";

    export default {
        name: "front-layout",
        components: {
            TopMenu,
            AppFooter,
            SvgSprite,
        },
        props: {
            isHomepage: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
