import Model from './Model'
import newCounts from './helpers/new-counts'

export default class Artist extends Model {
    // Set the resource route of the model
    resource() {
        return 'artists'
    }

    static getNewCounts = async function (days = 10, format = 'Y-m-d') {
        return newCounts('/api/artists/new-counts', days, format)
    }
}
